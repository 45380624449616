import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { Link } from 'react-router-dom';
// import { faHome } from '@fortawesome/free-solid-svg-icons';

const Error404 = () => {
  return (
    <Card className="text-center">
      <Card.Body className="p-5">
        <div className="display-1 text-300 fs-error">...</div>
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          Em construção
        </p>
        <hr />
        <p>O conteúdo estará disponível em breve, para mais informações:</p>
        <br />
        <a href="mailto:dev@opavendeu.com.br" className="ms-1">
          <FontAwesomeIcon icon="fa-regular fa-envelope" />
          <Button>Fale conosco</Button>
        </a>
      </Card.Body>
    </Card>
  );
};

export default Error404;
